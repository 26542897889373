main {
  min-height: 80vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 1.5px;
}

.empImg {
  max-height: 65%;
}

.bannerLogo {
  max-height: 275px;
  border: 1px solid black;
}

.serviceTitle {
  min-width: 18%;
  /* background-color: #1a1a1a; */
  background-color: black;
  color: white;

}

.serviceDesc {
  font-size: medium;
  text-align: center;
  background-color: #1a1a1a;
  color: white;
}
